<template>
  <b-modal
    id="ModalImportRatesCoverages"
    :title="$t(`Actualizar cobertura`)"
    modal-class="dialog-500"
    centered
    @close="close"
  >
    <div>
      <b-form-file
        class="attached-button-1 mt-2"
        v-model="file"
        :browse-text="$t('Buscar')" 
        :placeholder="$t('Escoja un archivo')"
        :drop-placeholder="$t('Suelte su archivo aquí')"
        accept=".csv, .zip"
      />
    </div>
    <template #modal-footer>
      <b-alert v-model="errorUpdate" v-height-fade.appear variant="danger" class="w-100">
        <div class="alert-body">
          {{ $t('Error al subir archivo:') }}
          {{ dataErrorsFile }}
        </div>
      </b-alert>
      <div class="w-100">
        <b-button class="float-right" :disabled="loading.updatingData" variant="warning" @click="ok">
          <template v-if="loading.updatingData">
            <b-spinner small  /> {{ $t('Cargando Archivo') }}
          </template>
          <template v-else>
            {{ $t('Cargar Archivo') }}
          </template>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import BaseServices from '@/store/services/index'
import { BNavItem } from 'bootstrap-vue'

export default {
  name: 'ModalImportRatesCoverages',
  props: ['item'],
  data() {
    return {
      file: null,
      data: {},
      errorUpdate: false,
      dataErrorsFile: '',
      baseService: new BaseServices(this),
      loading: {
        updatingData: false
      }
    }
  },
  methods: {
    ok() {
      this.loading.updatingData = true
      this.errorUpdate = false
      this.dataErrorsFile = ''
      if (this.file) {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
          carrier_id: this.$route.params.carrier_id,
          service_id: this.$route.params.service_id,
          rate_id: this.item.id
        }
        this.data = {
          file: this.file
        }
        this.baseService.callUploadFile('putUpdateRateCoverageNY', this.data, params, this.$session.get('cas_user'))
          .then(response => {
            this.$success(response.code)
            this.close()
          })
          .catch(err => {
            this.errorUpdate = true
            this.dataErrorsFile = err.message
          }).finally(() => {
            this.loading.updatingData = false
          })
      } else {
        this.errorUpdate = true
        this.dataErrorsFile = 'Debe adjuntar un archivo.'
        this.loading.updatingData = false
      }
    },
    close() {
      this.errorUpdate = false
      this.dataErrorsFile = ''
      this.$bvModal.hide('ModalImportRatesCoverages')
    }
  }
}
</script>
