<template>
  <div>
    <b-card>
      <div v-show="!loading.rates">
        <table-render id="carrier-service-rates" :schema="schema" :rows="rows" :actions="actions">
        </table-render>
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.rates"
      />
      <div class="table-render-skeleton" v-show="loading.rates">
        <b-skeleton-table
          :rows="3"
          :columns="schema.length || 3"
          :table-props="{}"
        />
      </div>
      <ModalImportRatesCoverages :item="item"></ModalImportRatesCoverages>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalImportRatesCoverages from './ModalImportRatesCoverages.vue'

export default {
  name: 'carrier-service-rates',
  components: { ModalImportRatesCoverages },
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      carrierName: '',
      carrierCode: '',
      item: '',
      loading: {
        rates: true
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      carrierServiceRates: 'getCarrierServiceRatesNY',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    carrierServiceRates() {
      this.rows = this.carrierServiceRates.rows
    },
    generalLoading: {
      handler () {
        this.loading.rates = !!this.generalLoading.getCarrierServiceRatesNY
      }
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'id', sortable: true, key: 'id' },
        { label: 'Codigo del Courier', sortable: true, key: 'carrier_code' },
        { label: 'Id servicio del courier', sortable: true, key: 'carrier_service_id' },
        { label: 'Codigo Pais', sortable: true, key: 'country_code' },
        { label: 'Fecha Validación', sortable: true, key: 'validity_date' },
        { label: 'Opciones', key: 'actions', class: ['text-center'] }
      ]
      this.actions = [
        {
          action: id => this.goToCoverages(id),
          icon: 'GlobeIcon',
          color: 'success',
          text: 'Rates'
        },
        {
          action: id => this.updateCoverage(id),
          icon: 'UploadIcon',
          color: 'success',
          text: 'Actualizar cobertura'
        }
      ]
      this.getCarrierServiceRatesNY()
    },
    getCarrierServiceRatesNY() {
      const params = { carrier_id: this.$route.params.carrier_id, service_id: this.$route.params.service_id  }
      this.$store.dispatch('fetchService', { name: 'getCarrierServiceRatesNY', params })
    },
    goToCoverages(id) {
      const params = { 
        carrier_id: this.$route.params.carrier_id, 
        service_id: this.$route.params.service_id, 
        rate_id: id 
      }
      this.$router.push({ name: 'carrier-service-rate-coverages', params })
    },
    updateCoverage(id) {
      this.item = this.rows.find(el => el.id === id)
      this.$bvModal.show('ModalImportRatesCoverages')
    }
  }
}
</script>
